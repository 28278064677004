header {
    position: relative;
    z-index: 1000;
    width: 100%;
    height: 100px;
    background: #fff;
    padding: 16px 35px 14px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.17);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.17);
}

nav {
    margin: 0 auto;
    position: relative;
    display: table;
    height: 100%;
    float: right;
    z-index: 1000;
}

.sticky-wrapper {
    height: 100px;
}

#navbar h1 {
    height: 80%;
    display: table-cell;
    font-size: 20px;
    position: relative;
    float: left;
    cursor: pointer;
}

#navbar .tagline {
    float: left;
    font-size: 13px;
    padding: 0 0 0 20px;
    position: relative;
    display: table;
    height: 100%;
    margin: -1px auto 0;
}

#navbar .tagline span {
    display: table-cell;
    vertical-align: middle;
    padding: 0 0 0 20px;
    border-left: 1px dotted #c0c0c0;
}

#nav {
    display: table-cell;
    vertical-align: middle;
}

#nav li {
    float: left;
    margin: 0 0 0 25px;
    cursor: pointer;
    position: relative;
    list-style-type: none;
}

#nav li.external {
    border-left: 1px dotted #c0c0c0;
    padding-left: 25px;
}

#nav li a {
    color: #50596c;
    text-decoration: none;
    display: block;
    padding: 3px 0;
}

#nav li:hover a {
    color: #2b2b2b;
}

@media only screen and (min-width: 1000px) {
    #nav { display: table-cell !important }
    #nav-button{ display: none; }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
    header {
        padding: 16px 0;
    }

    header h1 {
        padding-left: 20px;
    }

    #nav-button {
        font-size: 25px;
        display: block;
        float: right;
        width: 25px;
        margin-top: 16px;
        margin-right: 20px;
        cursor: pointer;
        position: relative;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .nav-bar {
        background-color: #2b2b2b;
        border-radius: 1px;
        display: block;
        width: 25px;
        height: 3px;
        margin: 3px auto;
    }

    nav {
        margin: 0;
        position: relative;
        display: block;
        height: auto;
        float: none;
        clear: both;
    }

    #nav {
        display: none;
        width: 100%;
        z-index: 300;
        float: none;
        position: relative;
        top: 0;
        list-style: none;
        border-bottom: 1px solid #ccc;
    }

    #nav li {
        text-align: center;
        border-top: 1px solid #ccc;
        float: none;
        margin: 0;
        display: block;
        background-color: #ffffff;
    }

    #nav li a {
        padding: 15px;
    }

    .menu-line {
        display: none !important;
    }

    #nav li.external {
        border-left: 0;
        padding-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    header {
        padding: 0;
        height: 60px;
    }

    header h1 {
        padding-left: 20px;
    }

    #navbar h1 {
        height: 50%;
    }

    #nav-button {
        font-size: 25px;
        display: block;
        float: right;
        width: 25px;
        margin-top: 16px;
        margin-right: 20px;
        cursor: pointer;
        position: relative;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .nav-bar {
        background-color: #2b2b2b;
        border-radius: 1px;
        display: block;
        width: 25px;
        height: 3px;
        margin: 3px auto;
    }

    nav {
        margin: 0;
        position: relative;
        display: block;
        height: auto;
        float: none;
        clear: both;
        width: 100%;
    }

    #nav {
        display: none;
        width: 100%;
        z-index: 300;
        float: none;
        position: relative;
        top: 0;
        list-style: none;
        border-bottom: 1px solid #ccc;
    }

    #nav li {
        text-align: center;
        border-top: 1px solid #ccc;
        float: none;
        margin: 0;
        display: block;
        background-color: #ffffff;
    }

    #nav li a {
        padding: 15px;
    }

    .menu-line {
        display: none !important;
    }

    #nav li.external {
        border-left: 0;
        padding-left: 0;
    }
}
