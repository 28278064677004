@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-16px);}
    60% {transform: translateY(-8px);}
}

/* fullscreen header */
#Header {
    height: 100%;
    width: 100%;
    display: block;
    margin: 0;
}

.overlay {
    display: block;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 10;
    width: 100%;
    height: 100%;
}

.header {
    display: table;
    position: absolute;
    text-align: center;
    top: 50%;
    height: 140px;
    margin-top: -70px;
    width: 100%;
    z-index: 111;
}

.header_center {
    display: table-cell;
    vertical-align: middle;
}

.header_center ul {
    display: block;
    text-align: center;
    width: 100%;
}

.header_center ul > li > h2 {
    font-size: 5.5em;
    color: #fff;
    margin-bottom: 8px;
}

.header_center ul > li > p {
    color: #fff;
    font-size: 24px;
}

#scroll-down {
    position: absolute;
    bottom: 15px;
    left: 50%;
    z-index: 1000;
    display: block;
    font-size: 30px;
    color: white;
}

#scroll-down:hover {
    text-decoration: none;
    cursor: pointer;
}

#scroll-down .icon-mouse {
    display: block;
    font-size: 22px;
    margin-bottom: 5px;
    margin-left: -5px;
    animation: bounce 2s linear infinite;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {

}

@media only screen and (max-width: 767px) {
    .header_center ul > li > h2 {
        font-size: 3em;
        color: #fff;
        margin-bottom: 8px;
    }

    .header_center ul > li > p {
        color: #fff;
        font-size: 14px;
    }
}
