#Skill .row:last-child, .skills .row .skill-bar:last-child {
    border-bottom: none;
}

.skill-bar {
    margin-bottom: 50px;
}

.skill-bar span {
    font-size: 16px;
}

.skill-bar .progress {
    position: relative;
    height: 2px;
    display: block;
    width: 100%;
    background-color: #A2A2A2;
    border-radius: 2px;
    margin: 0.5rem 0 1rem 0;
    overflow: visible;
}

.skill-bar .progress .level {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #2b2b2b;
    -webkit-transition: width .3s linear;
    -moz-transition: width .3s linear;
    -o-transition: width .3s linear;
    -ms-transition: width .3s linear;
    transition: width .3s linear;
    width: 1px;
}

.skill-bar .level .fa {
    font-size: 12px;
    position: absolute;
    top: -5px;
    right: 0;
    margin-left: 50%;
}
