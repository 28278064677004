footer {
    min-height: 100px;
    margin: 0 0 0 0;
    background: #fff;
    width: 100%;
}

footer .container {
    padding: 34px 10px 0 10px;
}

footer p {
    color: #8d8d8d;
    line-height: 32px;
}

footer p a {
    color: #8d8d8d;
    border: 0;
    display: inline-block;
}

footer p a:hover {
    color: #555;
    border: 0;
}

footer p a,
footer p a:hover,
footer p a:active,
footer p a:visited,
footer p a:focus {
    text-decoration: none;
}

.icon-social-facebook {
    color: #3b5998;
}

.icon-social-twitter {
    color: #3989e5;
}

.icon-social-google {
    color: #b53021;
}

.icon-social-linkedin {
    color: #4483af;
}

.icon-social-github {
    color: #24292e;
}

.icon-spacer {
    margin: 10px;
}

.icon-size {
    font-size: 20px;
}

@media only screen and (max-width: 480px) {

}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

@media only screen and (max-width: 767px) {
    .icon-spacer {
        margin: 5px;
    }

    .icon-size {
        font-size: 10px;
    }
}