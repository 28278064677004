html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #50596c;
  position: absolute;
  height: 100%;
  width: 100%;
}

*,
*:after,
*:before {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 35px;
}

h4 {
  font-size: 26px;
}

button:focus {
  outline: 0;
}

.App {
  height: 100%;
}

.title-divider {
  width: 20px;
  border: solid 1px;
  margin: auto;
}

.extra-thin {
  font-weight: 100;
}

.thin {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}

#root {
  height: 100%;
}

section {
  position: relative;
  padding: 50px 0 70px 0;
  display: block;
  margin-top: 30px;
}

section .row {
  padding-bottom: 50px;
  margin-top: 50px;
}

.border-bottom {
  border-bottom: 1px solid #d8d8d8;
}

.container {
  width: 960px;
  position: relative;
  margin: 0 auto;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* ----- clear fix for floats ----- */
.clearfix {
  display: inline-block;
}

* html .clearfix {
  height: 1%;
}

/* hides clearfix from IE-mac */
.clearfix {
  display: block;
}

.centered {
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .container {
    padding: 0;
    width: 640px;
    position: relative;
  }
}

@media only screen and (max-width: 480px) {
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 25px;
  }

  h4 {
    font-size: 20px;
  }

  section {
    padding: 10px 0 10px 0;
    margin-top: 0;
  }

  section .row {
    padding-bottom: 20px;
    margin-top: 10px;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .container {
    padding: 0;
    width: 320px;
    position: relative;
  }
}
