#Profile .img-profile {
  width: 180px;
  height: 180px;
  margin-bottom: 30px;
}

#Profile p {
  font-size: 21px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 30px;
}

.btn-outlined,
.btn-outlined:visited,
.btn-outlined:link,
.btn-outlined:active {
  color: #fff;
  border: 1px solid #555;
  background-color: #2b2b2b;
  border-radius: 1px;
  font-size: 20px;
  padding: 12px;
  font-weight: 200;
}

.btn-outlined:hover {
  text-decoration: none;
  background-color: #454545;
}

@media only screen and (max-width: 480px) {
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

@media only screen and (max-width: 767px) {
  #Profile .img-profile {
    width: 100px;
    height: 100px;
  }

  #Profile p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }

  .btn-outlined,
  .btn-outlined:visited,
  .btn-outlined:link,
  .btn-outlined:active {
    font-size: 16px;
    padding: 10px;
  }
}
