.accordion {
    margin: 5px 0 45px 0;
    border-top: 1px dotted #c0c0c0;
}

.open {
    transform: rotate(180deg);
}

.tab {
    background: #fff;
    background-size: 28px 16px;
    border-bottom: 1px dotted #c0c0c0;
    margin-bottom: 0;
    position: relative;
}

.time-range {
    width: 260px;
    float: left;
    margin-right: 20px;
    font-size: 21px;
    padding: 45px 0 40px 40px;
    letter-spacing: -1px;
    text-transform: uppercase;
    height: 100%;
}

.time-range span {
    font-size: 13px;
    padding-right: 6px;
    letter-spacing: 0;
}

.description {
    width: 660px;
    padding: 14px 60px 7px 40px;
    border-left: 1px dotted #c0c0c0;
    margin: 25px 0;
    float: left
}

.description h4 {
    margin-bottom: 8px;
    margin-top: 0;
    font-size: 28px;
    text-transform: none;
    letter-spacing: 0;
}

.description h5 small {
    font-size: 13px;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
    .time-range {
        width: 150px
    }

    .description {
        width: 450px;
    }
}

@media only screen and (max-width: 480px) {

}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

@media only screen and (max-width: 767px) {
    .accordion .tab {
        padding-left: 10px;
        padding-right: 10px;
    }

    .description h4 {
        font-size: 24px;
    }

    .time-range {
        width: 100%;
        padding: 35px 0 0 0
    }

    .description {
        width: 100%;
        border: 0;
        padding: 0
    }
}
